import React, { useState } from 'react';
import Slider from "react-rangeslider";
import classes from "./RateOfReturnYouComp/RateOfReturnYouComp.module.scss";
import { setRateOfReturnSpouse } from "../../../../store/actions/investorProfileActions";
import { connect } from "react-redux";
import 'react-rangeslider/lib/index.css'


const RateOfReturnSpouseComp = ( props ) => {
    const [ value, setValue ] = useState( props.rateOfReturnSpouse || 6.57);
    const horizontalLabels = {
        8.07: `8.07%`,
        0: `0%`
    }

    const onChangeRangeHandler = value => {
        const val = Number((value).toFixed(2))
        props.setRateOfReturnSpouse(val)
        setValue(val)
    }

    const format = val => parseFloat(val).toFixed(2) + '%'
    return (
        <React.Fragment>
            <div className="sliderWrap">
                <Slider
                    min={0}
                    max={8.07}
                    step={0.01}
                    value={value}
                    handleLabel={`${value}%`}
                    tooltip={false}
                    format={format}
                    labels={horizontalLabels}
                    onChange={val => onChangeRangeHandler(val)}
                />
            </div>
            <div className={classes.RateDescription}>
                <h3>Rate of Return from 2000 to 2019</h3>
                <p>* The Rate of Return from 2000 to 2019 for the Balanced profile is 6.57% consisting of 50% S&P/TSX Composite Total Return Index and 50% FTSE TMX Canada Universe Bond Index. Past returns are not necessarily indicative of future returns.</p>
            </div>
        </React.Fragment>

    )
};

function mapStateToProps(state) {
    return {
        rateOfReturnSpouse: state.investorProfile.rateOfReturnSpouse,
    }
};

function mapDispatchToProps(dispatch) {
    return {
        setRateOfReturnSpouse: val => dispatch(setRateOfReturnSpouse(val))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(RateOfReturnSpouseComp);